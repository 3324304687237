import { React, useEffect } from 'react';

import Cards from '../componentes/Cards';

const Home = () => {

  useEffect(() => {
    // getProva();
  }, []);

  return (
    <>
     
        <Cards />
        
    </>
  );
};

export default Home;