import React, { useState } from "react";

import * as toasts from '../utils/toasts';

import api from '../services/api';

import { Modal} from 'react-bootstrap';
import { Button } from "reactstrap";

export function Answer(props) {
    const [show, setShow] = useState(false);
    
   const [ddd, setDdd] = useState('');
   const [candidato, setCandidato] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const sendVoto = async () => {
      const voto = {
       ddd: ddd,
       candidato: props.candidato,
      }


      if (!validarVoto(voto)) {
        return false
      }
      
     setShow(false);

     await api.post(`/votos`, voto);

    toasts.notifySuccess('Voto encaminhado com sucesso');

    }
    
    const modalCandidato = () => {
    handleShow();
    }

    const validarVoto = (voto) => {
      const ddd = document.getElementById('ddd').value;
      const candidato = document.getElementById('candidato').value;
      

      if (!ddd) {
        toasts.notifyError('Campo DDD obrigatório');
      return false
      }

      if (!candidato) {
        toasts.notifyError('Campo candidato obrigatório');
      return false
      }

      return true;
    }

    return (
        <>
          <div className="d-grid gap-2 text-center">
            <Button variant="primary" size="lg" onClick={modalCandidato}>
              Votar
            </Button>
          </div>
          <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
              <Modal.Title>Confirme seu voto</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <form>
                  <div className="form-group app-label">
                  <label>DDD: <span class="text-danger">*</span></label>
                  <div className="row">
                    <div className="col-lg-12">
                    <input type="text" id="ddd" placeholder="Digite seu DDD caso for de outro pais, digite + Ex: +1" className="form-control resume" onChange={e => setDdd(e.target.value)} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <label>Candidato: <span class="text-danger"></span></label>
                    <input type="text" id="candidato" className="form-control resume" value={props.candidato}/>
                  </div>
                </div>
                  </form>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Fechar
                  </Button>
                  <Button variant="primary" onClick={sendVoto}>
                    Enviar
                  </Button>
              </Modal.Footer>
          </Modal>
        </>
    )
}