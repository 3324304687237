import Reactc from "react";

import { CardBody, CardImg } from "reactstrap";
import { Card, Row, Col, Alert } from "react-bootstrap";

import { Header } from './Header'
import { Answer } from './Modal'
import { Perfil } from './Footer'

import bolssonaroImg from "../bg/jair-bolsonaro.png";
import ciroImg from "../bg/ciro-gomes.png";
import lulaImg from "../bg/lula.png";
import pabloImg from "../bg/pablo-marçal.png";
import AndreImg from "../bg/andré-janones.png";
import SimoneImg from "../bg/simone-tabete.png";

const Enquente = () => {

  return (
    <>
    <Header />
    <br />
    <br />
    <Row xs={1} md={3} className="g-4"  style={{marginLeft: '82px'}}>
        <Col className="p-3">
          <Card style={{ width:  '200px'}}>
            <CardBody style={{ borderRadius:  '25px'}}>
              <CardImg top className="img-fluid" src={pabloImg} alt="Pablo" style={{ borderRadius:'10px', marginLeft: '29px', width: '100px'}} />
                <h5 style={{ marginLeft: '24px'}}>PABLO MARÇAL</h5>
                <Answer candidato="PABLO MARÇAL" />
            </CardBody>
          </Card>       
        </Col>

        <Col className="p-3">
          <Card  style={{ width:  '200px'}}>
            <CardBody style={{ borderRadius:  '25px'}}>
              <CardImg top className="img-fluid" src={SimoneImg} alt="SIMONE" style={{ borderRadius:  '10px', marginLeft: '29px', width: '100px'}} />
              <h5 style={{ marginLeft: '24px'}}>SIMONE TEBET</h5>
              <Answer candidato="SIMONE TABETE" /> 
            </CardBody>
          </Card>
        </Col>

        <Col className="p-3">
          <Card style={{ width:  '200px'}}>
            <CardBody style={{ borderRadius:  '25px'}}>
              <CardImg top className="img-fluid" src={AndreImg} alt="André" style={{ borderRadius:  '10px', marginLeft: '29px', width: '100px'}} />
              <h5 style={{ marginLeft: '24px'}}>ANDRÉ JANONES</h5>
              <Answer candidato="ANDRÉ JANONES"/> 
            </CardBody>
          </Card>
        </Col>

        <Col className="p-3">
        <Card style={{ width:  '200px'}}> 
          <CardBody style={{ borderRadius:  '25px'}}>
            <CardImg top className="img-fluid" src={bolssonaroImg} alt="Bolsonaro" style={{ borderRadius:  '10px', marginLeft: '29px', width: '100px'}} />
            <h5 style={{ marginLeft: '24px'}}>JAIR BOLSONARO</h5>
            <Answer candidato="JAIR BOLSONARO"/> 
          </CardBody>
        </Card>
        </Col>
        <Col className="p-3">
          <Card style={{ width:  '200px'}}>
            <CardBody style={{ borderRadius:  '25px'}}>
              <CardImg top className="img-fluid" src={ciroImg} alt="Ciro" style={{ borderRadius:  '10px', marginLeft: '29px', width:'100px'}} />
              <h5 style={{ marginLeft: '24px'}}>CIRO GOMES</h5>
              <Answer candidato="CIRO GOMES"/> 
            </CardBody>
          </Card>
        </Col>

        <Col className="p-3">
        <Card style={{ width:  '200px'}}>
          <CardBody style={{ borderRadius:  '25px'}}>
            <CardImg top className="img-fluid" src={lulaImg} alt="Lula" style={{ borderRadius:  '10px', marginLeft: '29px', width: '100px'}} />
            <h5 style={{ marginLeft: '24px'}}>LUIZ INÁCIO </h5>
            <Answer candidato="LULA"/>           
          </CardBody>
        </Card>
      </Col>
    </Row>
    <div>
      <Perfil />
      <Alert style={{ width:'100%'}} variant="primary">
      <font className="textinfo1">
        ⚠️ ENQUETE <u>NÃO</u> É PESQUISA
      </font>
      <br />
      <font className="textinfo2">
        Enquete não possui valor científico e não pode ser confundida com pesquisa
        eleitoral.
        <a
          href="https://www.google.com/search?q=diferen%C3%A7a+entre+enquete+e+pesquisa&hl=pt-BR&sxsrf=ALeKk02XA6h9LzpxZ0nfyutsao0VT9-wBQ%3A1628169365629&source=hp&ei=leQLYeKnJL3Q1sQP3v-hoAM&iflsig=AINFCbYAAAAAYQvypT0Q6_Acxv_tAYCJt8EIPJLgjL_g&oq=Diferen%C3%A7a+entre+enquete+&gs_lcp=Cgdnd3Mtd2l6EAMYADIFCAAQgAQyBQgAEIAEMgYIABAWEB4yBggAEBYQHjIICAAQFhAKEB46BwgjEOoCECc6BAgjECc6CwgAEIAEELEDEIMBOhEILhCABBCxAxCDARDHARDRAzoECAAQQzoICAAQgAQQsQM6CggAELEDEIMBEEM6BQgAELEDOgYIABAKEENQiwpY-x9gryZoAXAAeACAAbABiAG2G5IBBDAuMjSYAQCgAQGwAQo&sclient=gws-wiz"
          style={{ textDecoration: "none" }}
          target="_blank"
        >
          Saiba mais.
        </a>
      </font>
      </Alert>
    </div>
    </>
  );
};

export default Enquente;